<script>
import ExplainAd from "~/components/ExplainAd.vue";

export default {
  name: "AdPositionResearch",
  components: {ExplainAd},
  props: {
    AdPositionResearch: {
      type: Object,
      required: true
    }
  },
  computed: {
    isFounded() {
      return this.AdPositionResearch.data !== null;
    }
  }
}
</script>

<template>
  <div>
    {{AdPositionResearch.id }}
    <div v-if="AdPositionResearch && AdPositionResearch.data">
      <ExplainAd :explain="AdPositionResearch.data"></ExplainAd>
    </div>
    <el-row :gutter="16">
      <el-col :span="8">
        <div class="statistic-card">
          {{AdPositionResearch.position}}
          {{AdPositionResearch.page}}
          <el-statistic :value="AdPositionResearch.position" :precision="0">
            <template #title>
              <div style="display: inline-flex; align-items: center">
                Position
              </div>
            </template>
          </el-statistic>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="statistic-card">
          <el-statistic :value="AdPositionResearch.page" :precision="0">
            <template #title>
              <div style="display: inline-flex; align-items: center">
                Page
              </div>
            </template>
          </el-statistic>
        </div>
      </el-col>
    </el-row>

  </div>
</template>

<style scoped>

</style>