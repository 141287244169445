<script>
// import
import {defineComponent} from 'vue'
import ExplainAd from "~/components/ExplainAd.vue";

export default defineComponent({
  components: {
    ExplainAd
  },
  name: "ExplainPanel",
  props: {
    explains: {
      type: Array,
      default () {
        return []
      }
    }
  },
  watch: {
    pertinenceCriterion: {
      handler (val) {
        console.log(val)
        if (Array.isArray(val)) {
          let filters = {
            ...this.$route.query,
            pertinence: val.join(',')
          }
          this.$router.push({query: filters})
        }
      },
      deep: true
    }
  },
  data () {
    return {
      form: {},
      pertinenceCriterion: [],
      pertinenceGroup: [
        {
          label: 'Propriétaire',
          options: [
            {
              value: 'ownerLastLoginScore',
              label: 'Date de connexion'
            },
            {
              value: 'ownerPictureScore',
              label: 'Photo de profil'
            },
            {
              value: 'ownerAcceptedRentalsRateScore',
              label: 'Taux d\'acceptation des locations'
            },
            {
              value: 'ownerRentalDoneScore',
              label: 'Nombre de locations réalisées'
            },
            {
              value: 'ownerOnlineAdsScore',
              label: 'Nombre d\'annonces en ligne'
            },
            {
              value: 'ownerAvgReviewScore',
              label: 'Note moyenne des avis'
            },
            {
              value: 'negativeBoostScore',
              label: 'Boost négatif'
            },
            {
              value: 'wilsonScore',
              label: 'Wilson'
            }
          ],
        },
        {
          label: 'Annonce',
          options: [
            {
              value: 'adPicturesCountScore',
              label: 'Nombre de photos'
            },
            {
              value: 'adRentedCountScore',
              label: 'Nombre de locations réalisées'
            },
            {
              value: 'adUserFavCountScore',
              label: 'Nombre de fois ajouté aux favoris'
            },
            {
              value: 'addPublishDateScore',
              label: 'Date de publication'
            },
            {
              value: 'boostScore',
              label: 'Boost'
            },
            {
              value: 'adAvgUserRate',
              label: 'Note moyenne des avis'
            },
            {
              value: 'adPositionScore',
              label: 'Position de l\'annonce'
            }
          ],
        }
      ],
      pertinenceCriteria: [

      ],
    }
  },
  computed: {

  }
})
</script>

<template>
  <el-form :model="form" label-width="50%" label-position="left" size="large">
    <el-form-item label="Critère de pertinences">
      <el-select multiple
                 clearable
                 collapse-tags
                 collapse-tags-tooltip v-model="pertinenceCriterion" style="width:100%">
        <el-option-group v-for="group in pertinenceGroup" :key="group.label" :label="group.label">
          <el-option
              v-for="option in group.options"
              :key="option.value"
              :label="option.label"
              :value="option.value"
          />
        </el-option-group>
      </el-select>
    </el-form-item>
  </el-form>
  <ExplainAd v-for="(explain) in explains" :explain="explain">
  </ExplainAd>
</template>

<style scoped>
.item-full-with {

}
</style>