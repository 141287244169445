<script>

export default {
  name: "ExplainAd",
  props: {
    explain: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      showStatistics: false,
      scriptTitles: {
        "script score function, computed with script:\"Math.max(0 as int, 100 - (10 * (((new Date().getTime() - doc['user.lastLogin'].date.getMillis()) / 1000 / 60 / 60 / 24))) as int)": "Script Score - User Last Login",
        "field value function: none(doc['user.acceptedLocationsRate'].value?:75.0 * factor=1.0)": "Field Value - User Accepted Locations Rate",
        "script score function, computed with script:Math.min(100, doc['user.nbRentalsDone'].size() == 0 ? 0 : doc['user.nbRentalsDone'].value)": "Script Score - User Number of Rentals Done",
        "script score function, computed with script:\"Math.min(100, doc['user.totalOnlineAnnonces'].value)": "Script Score - User Total Online Annonces",
        "field value function: none(doc['user.notationAverage'].value * factor=20.0)": "Field Value - User Notation Average",
        "field value function: none(doc['totalImages'].value * factor=20.0)": "Field Value - Total Images",
        "script score function, computed with script:Math.min(100, doc['locationDone'].value*10)": "Script Score - Location Done",
        "script score function, computed with script:Math.min(100, doc['favorisCount'].value*5)": "Script Score - Favoris Count",
        "script score function, computed with script:Math.max(0 as int, 100 - (10 * (((new Date().getTime() - doc['createdAt'].date.getMillis()) / 1000 / 60 / 60 / 24))) as int)": "Script Score - Created At",
        "script score function, computed with script:doc['annonce.boost'].value ? doc['annonce.boost'].value : 0": "Script Score - Annonce Boost"
      },
      mapFunctionScore: {
        'ownerLastLoginScore': "script score function, computed with script:Math.max(0 as int, 100 - (10 * (((new Date().getTime() - doc['user.lastLogin'].date.getMillis()) / 1000 / 60 / 60 / 24))) as int)",
        'ownerPictureScore': "",
        'ownerAcceptedRentalsRateScore': "script score function, computed with script:Math.min(100, doc['user.nbRentalsDone'].size() == 0 ? 0 : doc['user.nbRentalsDone'].value)",
        // 'ownerAcceptedRentalsRateScore': "field value function: none(doc['user.acceptedLocationsRate'].value?:75.0 * factor=1.0)",
        'ownerRentalDoneScore': "field value function: none(doc['user.acceptedLocationsRate'].value?:75.0 * factor=1.0)",
        'ownerOnlineAdsScore': "script score function, computed with script:Math.min(100, doc['user.totalOnlineAnnonces'].value)",
        'ownerAvgReviewScore': "field value function: none(doc['user.notationAverage'].value * factor=20.0)",
        'adPicturesCountScore': "field value function: none(doc['totalImages'].value * factor=20.0)",
        'adRentedCountScore': "script score function, computed with script:Math.min(100, doc['locationDone'].value*10)",
        'adUserFavCountScore': "script score function, computed with script:Math.min(100, doc['favorisCount'].value*5)",
        'addPublishDateScore': "script score function, computed with script:Math.max(0 as int, 100 - (10 * (((new Date().getTime() - doc['createdAt'].date.getMillis()) / 1000 / 60 / 60 / 24))) as int)",
        'adAvgUserRate': "",
        'annonceBoost': "script score function, computed with script:doc['annonce.boost'].value ? doc['annonce.boost'].value : 0"

      }
    }
  },
  methods: {
    toggleStatistics () {
      this.showStatistics = !this.showStatistics;
    },
    getScoreImportance (score) {
      return this.allBoostScoresSum ? ((score.boostScore / this.allBoostScoresSum) * 100).toFixed(2) : 0;
    },
    findAllScore () {
      if (!this.explain || !this.explain._explanation) {
        return [];
      }
      this.explain._explanation.reduce((acc, obj) => {

        const score = this.findFunctionScore(obj);
        if (score !== null) {
          acc.push(score);
        }

        return acc;
      }, []);

      return [];
    },
    findFunctionScore (obj, parent = null, boost = null) {
      // console.log(`Searching in object with description: ${obj.description} = ${description}`);
      if (obj.description === "function score, product of:") {
        boost = obj.value;
      }
      if (obj.description.startsWith('script score function') ||obj.description.startsWith('field value function') ) {
        return {
          name: obj.description,
          score: obj.value,
          boostScore: boost
        }
      }

      if (Array.isArray(obj.details)) {
        for (let i = 0; i < obj.details.length; i++) {
          const score = this.findFunctionScore(obj.details[i], obj, boost);

          if (score !== null) {
            return score;
          }
        }
      }

      return null;
    },
    findScore (obj, description, parent = null, boost = null) {
      // console.log(`Searching in object with description: ${obj.description} = ${description}`);
      if (obj.description === "function score, product of:") {
        boost = obj.value;
      }
      if (obj.description === description) {
        // let boostScore = 0;
        // if (this.allBoostScoresSum) {
        //   boostScore = boost / this.allBoostScoresSum;
        // }
        return {
          name: obj.description,
          score: obj.value,
          boostScore: boost,
          // boostScoreRate: boostScore
        }
      }

      if (Array.isArray(obj.details)) {
        for (let i = 0; i < obj.details.length; i++) {
          const score = this.findScore(obj.details[i], description, obj, boost);

          if (score !== null) {
            return score;
          }
        }
      }

      return null;
    },
    findScoreParent(obj) {
      if (!obj) return null;
      if (obj.description === "function score, product of:") {
        return obj;
      }
      return this.findScoreParent(obj.parent);
    },
    getTitle (score) {
      const titles = {
        "user.lastLogin": "Date de connexion",
        "user.acceptedLocationsRate": "User Accepted Locations Rate",
        "user.nbRentalsDone": "User Number of Rentals Done",
        "user.totalOnlineAnnonces": "User Total Online Annonces",
        "user.notationAverage": "User Notation Average",
        "locationDone": "User Location Done",
        "totalImages": "Total Images",
        "position": "Position de l'annonce",
        "favorisCount": "Favoris Count",
        "createdAt": "Created At",
        "annonce.boost": "Annonce Boost",
        "avgUserRate": "Notation by ad",
        "negativeBoost": "Negative boost",
        "user.currentAdresse.location": "Proximity",
      }
      for (const [key, value] of Object.entries(titles)) {
        if (score.name.includes(key)) {
          return value;
        }
      }
      return score.name;
    },
    copyJsonToClipboard ()
    {
      let json = JSON.stringify(this.explain)
      navigator.clipboard.writeText(json).then(() => {
      })
    },
    copyJsonExplainToClipboard ()
    {
      let json = JSON.stringify(this.explain._explanation)
      navigator.clipboard.writeText(json).then(() => {
      })
    },
    scoreImportance (score) {
      if (score.boostScore) {
        return score.score * score.boostScore;
      }
      return score.score;
    },
  },
  computed: {
    scriptAndFieldValueDescriptions() {
      const descriptions = [];
      const traverse = (obj) => {
        if ('description' in obj &&
            (
                obj.description.startsWith('script score function') ||
                obj.description.startsWith('Function for field') ||
                obj.description.startsWith('field value function'))) {
          descriptions.push(obj.description);
        }
        if ('details' in obj && Array.isArray(obj.details)) {
          obj.details.forEach(traverse);
        }
      };
      if (!this.explain) {
        return [];
      }
      traverse(this.explain._explanation);
      return descriptions;
    },
    allScores () {
      return this.scriptAndFieldValueDescriptions.map(description => {
        try {
          let score = this.findScore(this.explain._explanation, description);
          score.title = this.scriptTitles[description]
          return score;
        } catch (e) {
          console.log(e)
        }
      });
    },
    allBoostScoresSum () {
      return this.allScores.reduce((acc, score) => {
        return acc + score.boostScore;
      }, 0);
    },
    scores () {
      return Object.keys(this.mapFunctionScore).reduce((scoresObj, key) => {
        const description = this.mapFunctionScore[key];
        // console.log('St'+ key)
        // console.log(description)
        try {
          scoresObj[key] = this.findScore(this.explain._explanation, description);
        } catch (e) {
          console.log(e)
        }
        // console.log(scoresObj)
        return scoresObj;
      }, {})
    },
    ad ( ) {
      return this.explain ? this.explain._source : {}
    },
    title () {
      return this.ad.titre
    },
    adCoverPath ()
    {
      let noImage = 'img/sans-photo.jpg'
      return this.ad && Array.isArray(this.ad.imagesannonce) && this.ad.imagesannonce.length && this.ad.imagesannonce[0].imageName ? '/display-media/images-upload/' + this.ad.user.id + '/annonces/' + this.ad.id + '/' + this.ad.imagesannonce[0].imageName + '?w=300&r=1' : noImage
    },
    fullCity () {
      if (this.ad && this.ad.user && this.ad.user.currentAdresse) {
        return this.ad.user.currentAdresse.ville + ', ' + this.ad.user.currentAdresse.codePostal
      }
      return ''
    },
    fullAdresse () {
      return ''
    },
    fullname () {
      return this.ad.user && this.ad.user.fullName ? this.ad.user.fullName : ''
    },
    shopPath () {
      return '/annonce/show/' + this.ad.id
    },
    moyenne () {
      return this.ad.user && this.ad.user.notationAverage ? this.ad.user.notationAverage : 0
    },
    nbStars () {
      const user = this.ad.user
      if (user) {
        return user.oneStar + user.twoStar + user.threeStar + user.fourStar + user.fiveStar
      } else {
        return 0
      }
    },
    price () {
      let price = false
      if (typeof this.ad.tarif === 'string') {
        price = this.ad.tarif
      } else if (this.ad.tarif){
        price = this.ad.tarif.prixUnJour
      }
      if (!price) {
        price = 0.00
      }
      return new Intl.NumberFormat("fr", {
        style: 'currency',
        currency: 'EUR'
      }).format(price)
    },
    statutsBadge () {
      let badge = false
      if (!this.ad.user) {
        return badge
      }
      if (this.ad.user.statutUser === 2 || this.ad.user.statutUser === 5) {
        badge = 'PRO'
      } else if (this.ad.user.statutUser === 4) {
        badge = 'LOUEUR PRO'
      } else if (this.ad.user.statutUser === 3) {
        badge = 'Association'
      }
      return badge
    },
    isExpert ()
    {
      return this.ad.user && this.ad.user.isExpert ? this.ad.user.isExpert : false
    }
  }
}
</script>

<template>
  <div class="explain-ticket">
    <div class="head-ticket">
      <img class="image-container" :src="adCoverPath" alt="">
      <div class="description">
        <p class="title">
          <a href="">{{ title }}</a>
        </p>
        <p class="text"><a class="text-overlay" href="/">{{fullCity}}</a></p>
        <p class="name">
          <a :href="shopPath">{{ fullname }}
            <span v-if="isExpert" class="expert-badge"><i class="fa fa-star" aria-hidden="true"></i> Expert</span>
            <label v-if="statutsBadge">{{statutsBadge}}</label> <i class="fa fa-truck invisible" aria-hidden="true"></i>
          </a>
        </p>
        <p class="text">
          <span class="stars">
            <span class="star" v-for="n in 5" :class="{'star-full': n <= moyenne}"></span>
          </span>
          <span class="nb-stars">{{nbStars}} avis</span>
          </p>
        <div style="margin-top: 20px">
          <el-button @click="copyJsonExplainToClipboard">Copy Explain JSON</el-button>
          <el-button @click="copyJsonToClipboard">Copy all JSON</el-button>
        </div>
      </div>
      <div>
        <el-button @click="toggleStatistics" circle>
          <el-icon>
            <Minus v-if="showStatistics" />
            <Plus v-else />
          </el-icon>
        </el-button>
<!--          <el-icon v-if=""><Minus /></el-icon>-->
      </div>
    </div>
    <div v-show="showStatistics" class="info">
      <el-row>
        <el-col :span="8">
          <div class="statistic-card">
            <el-statistic v-if="explain" :value="explain._score" :precision="3">
              <template #title>
                <div style="display: inline-flex; align-items: center">
                  Score global
                </div>
              </template>
            </el-statistic>
            <div class="statistic-footer">
              <div class="footer-item">
                <span>importance</span>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="statistic-card">
            <el-statistic :value="allBoostScoresSum" :precision="3">
              <template #title>
                <div style="display: inline-flex; align-items: center">
                  Score global pertinence
                </div>
              </template>
            </el-statistic>
            <div class="statistic-footer">
              <div class="footer-item">
                <span>importance</span>
              </div>
            </div>
          </div>
        </el-col>
        <el-col v-for="score in allScores" :span="8">
          <div class="statistic-card">
            <el-statistic :value="score.boostScore" :precision="3">
              <template #title>
                <div style="display: inline-flex; align-items: center">
                  {{ getTitle(score)}}
                  <el-tooltip
                      effect="dark"
                      :content="score.name"
                      placement="top"
                  >
                    <el-icon style="margin-left: 4px" :size="12">
                      <Warning />
                    </el-icon>
                  </el-tooltip>
                </div>
              </template>
            </el-statistic>
            <div class="statistic-footer">
              <div class="footer-item">
                <span>importance</span>
                <span class="green">
                  {{ getScoreImportance(score)}} %
                  <el-icon>
                    <CaretTop />
                  </el-icon>
                </span>
              </div>
            </div>
          </div>
        </el-col>
<!--        <el-col v-for="score in allScores" :span="6">-->
<!--          <el-statistic :title="score.title ? score.title : score.name" :value="score.boostScore" />-->
<!--        </el-col>-->
      </el-row>
<!--      <p>-->
<!--        <span v-for="score in allScores">-->
<!--          <span class="price">{{score.title ? score.title : score.name }}: </span>-->
<!--          <strong class="price-unit"> {{score.boostScore}}</strong>-->
<!--          <br>-->

<!--        </span>-->
<!--      </p>-->
    </div>
  </div>

</template>

<style scoped>
:global(h2#card-usage ~ .example .example-showcase) {
  background-color: var(--el-fill-color) !important;
}

.el-statistic {
  --el-statistic-content-font-size: 28px;
}

.statistic-card {
  height: 100%;
  padding: 20px;
  border-radius: 4px;
  background-color: var(--el-bg-color-overlay);
}

.statistic-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  font-size: 12px;
  color: var(--el-text-color-regular);
  margin-top: 16px;
}

.statistic-footer .footer-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.statistic-footer .footer-item span:last-child {
  display: inline-flex;
  align-items: center;
  margin-left: 4px;
}

.green {
  color: var(--el-color-success);
}
.red {
  color: var(--el-color-error);
}
.explain-ticket {
  position: relative;
  .head-ticket {
    display: grid;
    grid-template-columns: 150px auto 80px;
    grid-gap: 12px;
    grid-template-rows: 12px 12px 12px;
    height: 150px;
  }
  .image-container{
    align-items: flex-start;
    width: 150px;
    height: auto;
  }
  .text-overlay {
    margin: 10px 0;
  }
  .description p.text {

    font-size: 12px;
    line-height: normal;
  }

  .description p.text a {
      color: #a3a3a3 !important;

  }
  .description {
    align-items: stretch;
    padding: 12px 0 0 0;
  }

  .description p {
    margin: 0;
  }
  .description p.name {
    color: #3a8bbb !important;
    font-size: 12px;
    line-height: normal;
    font-weight: bold;
  }

  .description p.title {
    font-weight: 800;
    color: #080025;
    letter-spacing: -0.12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    line-height: normal;
  }
  .description p.title a {
    font-weight: 800;
    color: #080025;
    letter-spacing: -0.12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    line-height: normal;
  }

  .description p.title span {
    transition: all 0.2s linear;
    background: #FFEDF0;
    color: #fc2249;
    border-radius: 3px;
    margin-left: 3px;
    padding: 3px 6px 2px;
    display: inline-block;
    font-size: 9px;
    line-height: normal;
    text-transform: uppercase;
  }

  span.expert-badge {
    transition: all 0.2s linear;
    background: #FFEDF0;
    color: #fc2249;
    border-radius: 3px;
    margin-left: 3px;
    padding: 3px 6px 2px;
    display: inline-block;
    font-size: 9px;
    line-height: normal;
    text-transform: uppercase;
  }

  span.expert-badge i {
    color: #fc2249 !important;
  }

}
.item-full-width {
  width: 100%;
  height: 200px;
  margin-bottom: 20px;
  .image-container img{
    width: 100px;
    height: auto;
  }
}
</style>