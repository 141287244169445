<template>
  <section id="page">
    <div class="container-fluid catalogue-main np">
      <div class="left pull-left show-map" id="results-container">
        <div class="px-3">
          <div class="title-block pt-4">
            <div class="d-flex justify-content-between">
              <h1 class="searchTitle">{{ title }}</h1>
            </div>
            <span class="title-bottom">{{ subTitle }}</span>
          </div>

        </div>
        <SearchFilters
            :position="position"
        ></SearchFilters>
        <div v-if="isAdmin">
          <el-tabs v-model="activeName" class="demo-tabs">
            <el-tab-pane label="Recherche" name="result" class="px-3">
<!--              <SearchFilters :position="position"></SearchFilters>-->
              <div class="search-result np row mt-4">
                <AnnonceCard class="col-xs-6 col-sm-6 col-md-4 item-result" v-for="(searchAnnonce, index) in searchAnnonces" :ad="searchAnnonce" @contextmenu="onRightClick($event, index)" @mouseenter="enterAnnonce(searchAnnonce)" @mouseleave="leaveAnnonce(searchAnnonce)"/>
<!--                <Paginator v-if="searchResult" :nbItems="searchResult.hits.total" :currentPage="currentPage" @change="changePage"/>-->
              </div>
            </el-tab-pane>
            <el-tab-pane label="Calcul position" name="position">
              <el-input v-model="newAdPositionResearch.id"></el-input>
              <el-button :loading="searchAnnoncePositionLoading" @click="searchAnnoncePosition">Rechercher</el-button>
              <AdPositionResearch
                    v-for="(adPositionResearch) in adPositionResearches"
                    :key="adPositionResearch.search_key"
                    :AdPositionResearch="adPositionResearch"></AdPositionResearch>
            </el-tab-pane>
            <el-tab-pane label="Explications" name="explain">
              <ExplainPanel :explains="explainJson">
              </ExplainPanel>
<!--              <pre v-if="explainJson" >{{ explainJson }}</pre>-->
<!--              <ExplainationSearch v-if="explainItemScore && explainItemScore._explanation" :description="explainItemScore._explanation.description" :details="explainItemScore._explanation.details" :value="explainItemScore._explanation.value"></ExplainationSearch>-->
            </el-tab-pane>
            <el-tab-pane label="JSON Request" name="json">
              <h1>
                JSON Request
              </h1>
              <pre v-if="hasJsonRequest" >{{ JsonRequest }}</pre>
            </el-tab-pane>
          </el-tabs>
        </div>
<!--        <button @click="search">Load</button>-->
        <div v-if="!isAdmin" class="search-result np row mt-4">
          <AnnonceCard class="col-xs-6 col-sm-6 col-md-4 item-result" v-for="(searchAnnonce, index) in searchAnnonces" :ad="searchAnnonce" @contextmenu="onRightClick($event, index)" @mouseenter="enterAnnonce(searchAnnonce)" @mouseleave="leaveAnnonce(searchAnnonce)"/>
        </div>
        <div class="search-result np row mt-4">
          <Paginator v-if="searchResult" :nbItems="searchResult.hits.total" :currentPage="currentPage" @change="changePage"/>
        </div>
      </div>
    </div>
    <div class="search-result np" v-if="htmlResponse ">
      <div  style="width: 60%;">
        <div v-if="htmlResponse" v-html="htmlResponse"></div>
      </div>
    </div>
    <Map :position="position" :ads="searchAnnonces" ref="mapSection"></Map>
  </section>
  <el-drawer v-model="drawer" title="I am the title" :with-header="false">
    <ExplainationSearch v-if="explainItemScore && explainItemScore._explanation" :description="explainItemScore._explanation.description" :details="explainItemScore._explanation.details" :value="explainItemScore._explanation.value"></ExplainationSearch>
  </el-drawer>
</template>

<script>
import AnnonceCard from "./AnnonceCard.vue"
import ExplainationSearch from "./ExplainationSearch.vue"
import SearchFilters from "./SearchFilters.vue";
import Paginator from "./Paginator.vue";
import Map from './Map.vue'
import {reverseGeocoding} from '../services/geocoding.js'
import {searchAction} from "../services/search.js";
import {debounce, throttle} from "../utils/TimeExecution";
import ExplainPanel from "~/components/ExplainPanel.vue";
import ExplainAd from "~/components/ExplainAd.vue";
import AdPositionResearch from "~/modules/search/components/AdPositionResearch.vue";

export default {
  name: "SearchSection",
  components: {
    AdPositionResearch,
    ExplainAd,
    ExplainPanel,
    ExplainationSearch,
    AnnonceCard,
    SearchFilters,
    Map,
    Paginator
  },
  data () {
    return {
      findId: '',
      newAdPositionResearch: {
        search_key: 0,
        id: '',
        ad: null,
        position: 0,
        page: 0,
        loading: false,
        data: null,
        state: 0
      },
      adPositionResearches: [],
      searchAnnoncePositionData: null,
      searchAnnoncePositionValue: null,
      searchAnnoncePositionLoading: false,
      activeName: 'result',
      selectExplainationIndex: null,
      drawer: false,
      isResultLoading: false,
      searchResult: null,
      htmlResponse: false
    }
  },
  created () {
    // this.geolocalisation()
    // console.log('position')
    // console.log(this.position)
  },
  watch: {
    searchResult: {
      deep: true,
      async handler (result) {
        if (result && result.hits) {
          if (result.hits.total && result.hits.hits.length) {
            let query = this.$route.query
            delete query.p
            await this.$router.push({query})
          } else if (!result.hits.total) {

          }
        }
      }
    },
    $route: {
      immediate: true,
      deep: true,
      async handler (route) {
        this.searchActionThrottle()
        //     .then(this.saveSearch)
        //     .catch(this.handleError)
      }
    }
  },
  computed: {
    isAdmin () {
      return this.auth && Array.isArray(this.auth.rolesList) ? this.auth.rolesList.includes('ROLE_ADMIN') : false
    },
    JsonRequest () {
      return this.searchResult ? JSON.stringify(JSON.parse(this.searchResult.elasticRequest), null, 2) : null
    },
    hasJsonRequest () {
      return this.searchResult ? this.searchResult.elasticRequest : null
    },
    activeFilters () {
      return Object.keys(this.$route.query)
    },
    auth () {
      return this.$symfony.auth
    },
    modeles () {
      return this.searchResult && this.searchResult.modeles ? this.searchResult.modeles : []
    },
    marques () {
      return this.searchResult && this.searchResult.marques ? this.searchResult.marques : []
    },
    categories () {
      return this.searchResult && this.searchResult.categories ? this.searchResult.categories : []
    },
    explainJsonDebug ()
    {
      return this.searchResult && typeof this.searchResult.hits !== 'undefined' && Array.isArray(this.searchResult.hits.hits)
    },
    explainJson () {
      if (this.explainJsonDebug) {
        return this.searchResult.hits.hits
      } else {
        return []
      }
    },
    explainItemScore () {
      if (this.selectExplainationIndex !== null && !this.isResultLoading && typeof this.searchResult.hits.hits[this.selectExplainationIndex] !== 'undefined') {
        return this.searchResult.hits.hits[this.selectExplainationIndex]
      } else {
        return null
      }
    },
    isEnvDev () {
      return import.meta.env.DEV
    },
    isDev () {
      return this.$route.query && this.$route.query.dev
    },
    currentPage () {
      return this.$route.query.p ? parseInt(this.$route.query.p) : 1
    },
    totalHits () {
      return this.searchResult !== null ? new Intl.NumberFormat("fr").format(this.searchResult.hits.total) : ''
    },
    subTitle ()
    {
      let start = 'Parmi plus de '
      // if (this.currentPage > 1) {
      //   start = 'Page ' + this.currentPage + ' sur '
      // }
      // if (this.searchResult && this.searchResult.hits.total && this.searchResult.hits.total > 500) {
      //
      // }

      return this.searchResult === null ? '' : `${start}${this.totalHits} annonces `
    },
    searchAnnonces ()
    {
      return this.searchResult === null ? [] : this.searchResult.hits.hits.map((h) => {
        return h['_source']
      })
    },
    position: {
      get () {
        return Array.isArray(this.$route.query.c) && this.$route.query.c.length === 2 ? this.$route.query.c : typeof this.$route.query.c === 'string' ? this.$route.query.c.split(',') : []
      },
      set (val) {
        let filters = {
          ...this.$route.query,
          c: val.join(',')
        }
        this.$router.push({query: filters})
      }
    },
    isSearchActive ()
    {
      return typeof this.$route.query.q === 'string' && this.$route.query.q.length > 0
    },
    query () {
      return this.$route.query
    },
    title () {
      let title = typeof this.$route.query.q === 'string' && this.$route.query.q.length > 0 ? this.$route.query.q : 'Catalogue'
      // if ()
      if (this.modeles.length === 1) {
        title = this.modeles[0].nom
      } else if (this.marques.length === 1) {
        title = this.marques[0].nom
      } else if (this.categories.length === 1) {
        title = this.categories[0].nom
      }
      return title
    },
    userRole () {
      return Array.isArray(this.$symfony.roles) ? this.$symfony.roles : []
    }
  },
  methods: {
    async searchAnnoncePosition () {
      if (this.newAdPositionResearch.id) {
        await this.createNewAdPositionResearch()
      }
    },
    async createNewAdPositionResearch () {
      const clone = JSON.parse(JSON.stringify(this.newAdPositionResearch))
      clone.loading = true
      this.adPositionResearches.push(clone)
      this.batchSearchAnnoncePosition(clone)
      this.newAdPositionResearch = {
        id: '',
        ad: null,
        search_key: clone.search_key + 1,
        position: 0,
        page: 1,
        loading: false,
        data: null,
        state: 0
      }
    },
    async batchSearchAnnoncePosition (adSearch) {
      const params = {
        ...this.$route.query,
        p: adSearch.page ? adSearch.page : 1
      }
      const batch = await searchAction(params)
      console.log(batch)
      console.log(adSearch)
      if (batch && batch.data && batch.data.elasticResponse && batch.data.elasticResponse.hits && batch.data.elasticResponse.hits.hits && batch.data.elasticResponse.hits.hits.length) {
        const index = this.adPositionResearches.indexOf(adSearch);
        for (const hitKey in batch.data.elasticResponse.hits.hits) {
          // console.log(hitKey)
          // console.log(batch.data.elasticResponse.hits.hits[hitKey])
          // console.log(batch.data.elasticResponse.hits.hits[hitKey])
          // console.log(batch.data.elasticResponse.hits.hits[hitKey]._id)
          // console.log(this.findId)

          adSearch.position = (parseInt(adSearch.position) + 1)
          if (batch.data.elasticResponse.hits.hits[hitKey]._id === adSearch.id) {
            adSearch.data = batch.data.elasticResponse.hits.hits[hitKey]
            adSearch.loading = false
            adSearch.state = 2
            this.adPositionResearches.splice(index, 1, { ...adSearch });
            break;
          } else {

          }
        }
        if (adSearch.data === null) {
          adSearch.page = adSearch.page + 1
          this.adPositionResearches.splice(index, 1, { ...adSearch });
          await this.batchSearchAnnoncePosition(adSearch)
        }
      } else {
        adSearch.loading = false
        adSearch.state = 3
        this.adPositionResearches.splice(index, 1, { ...adSearch });
      }
    },
    onRightClick (event, index) {
      if (this.userRole.includes('ROLE_ADMIN') && this.isDev) {
        event.preventDefault()
        this.drawer = true
        this.selectExplainationIndex = index
      }
    },
    enterAnnonce (ad) {
      let marker = document.getElementById('marker-'+ad.id)
      if (marker) {
        marker.classList.add('active')
      }
    },
    leaveAnnonce (ad) {
      let marker = document.getElementById('marker-'+ad.id)
      if (marker) {
        marker.classList.remove('active')
      }
    },
    changePage (page) {
      const query = {
        ...this.$route.query,
        p: page
      }
      this.$router.push({query})
      window.scrollTo(0, 0);
    },
    searchActionThrottle: debounce(function () {
      this.isResultLoading = true
      searchAction(this.$route.query)
        .then(this.saveSearch)
        .catch(this.handleError)
        .finally(() => {
          this.isResultLoading = false
        })
    }, 800, false),
    handleError (error) {
      // console.log('error')
      if (error.message) {
        // console.error(message)
        console.error(error)
        return null
      }
      let response = error.response
      if (response.headers['content-type'].match('^text/html')) {

        let page = new DOMParser().parseFromString(response.data, 'text/html')
        this.htmlResponse = page.getElementById('content-min-len') !== null ? page.getElementById('content-min-len').outerHTML : page.outherHTML
      }
    },
    saveSearch (response) {
      // console.log(typeof response.headers['content-type'] !== 'undefined')
      if (response && response.headers && typeof response.headers['content-type'] !== 'undefined') {
        if (response.headers['content-type'].match('^text/html')) {
          this.htmlResponse = response.data
        } else if (response.headers['content-type'].match('^application/json')) {
          try {
            this.searchResult = response.data
          } catch (e) {
            console.error('during JSON parse')
            console.error(e)
            console.error(e.message)
          }
        }
      } else {
        // console.log('error in response object')
        // console.log(response.headers['content-type'] + '')
      }
      // if ()
    },
    search () {
      searchAction(this.$route.query)
        .then(this.saveSearch)
        .catch(this.handleError)
    },
    geodecoding ()
    {

    },
    geolocalisation ()
    {
      let options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      };
      let that = this
      function success(pos) {
        let crd = pos.coords;
        that.position = [crd.longitude, crd.latitude]
        reverseGeocoding(that.position)
      }

      function error(err) {
        console.warn(`ERREUR (${err.code}): ${err.message}`);
      }

      navigator.geolocation.getCurrentPosition(success, error, options);
    }
  }
}
</script>

<style>
  .el-tabs__header {
    margin: 0px 13px;
    margin-bottom: 15px;
  }
  html {
    scroll-behavior: smooth;
  }
</style>
