<template>
  <div class="display-flex justify-content-between px-3 mt-4 catalogue-filters" id="secondary-menu">
    <div class="left-search-parameters">
      <CityFilter :modelValue="position" @change="onPositionChange" class="search-parameter-item"/>
      <el-select v-model="StatutFilter" multiple clearable :clear-icon="'Delete'" collapse-tags placeholder="Type de loueur" class="select-status search-parameter search-parameter-item">
        <el-option
            v-for="item in UserStatutsOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
        />
      </el-select>
      <el-cascader v-model="categoriesFilter" :options="categoriesOptions" :props="TraitProps" :show-all-levels="false" placeholder="Categories" clearable collapse-tags collapse-tags-tooltip class="select-status search-parameter search-parameter-item">
      </el-cascader>

    </div>
    <div class="text-left no-border-select order-by-select right-search-parameters">
      <div class="order-by">Trier par :</div>
      <el-select v-model="OrderBy" fit-input-width class="search-filter" placeholder="">
        <el-option
            v-for="(item, index) in OrderByOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
        />
      </el-select>
    </div>
  </div>
</template>

<script>
import CityFilter from "./CityFilter.vue";
// import {Delete} from "element-plus/es";
export default {
  name: "SearchFilters",
  components: {
    CityFilter,
    // Delete
  },
  props: {
    position: {
      type: [Array]
    }
  },
  data () {
    return {
      TraitProps: {
        multiple: true,
        clearable: true
      },
      StatutFilter: [],
      categoriesFilter: [],
      OrderByOptions: [
        {
          value: 'pertinence',
          label: 'Pertinence'
        },
        {
          value: 'prix-asc',
          label: 'Prix croissants'
        },
        {
          value: 'prix-desc',
          label: 'Prix décroissants'
        },
        {
          value: 'nouveaute',
          label: 'Nouveauté'
        }
      ],
      UserStatutsOptions: [
        {
          value: 2,
          label: 'Professionnels',
        },
        {
          value: 4,
          label: 'Sociétés de location',
        },
        {
          value: 1,
          label: 'Particulier',
        }
      ]
    }
  },
  watch: {
    $route: {
      deep: true,
      immediate: true,
      handler (val) {
        if (val.query.u) {
          this.StatutFilter = [...val.query.u.split(',').map((v) => parseInt(v))]
        } else {
          this.StatutFilter = []
        }
        if (val.query.sc) {
          this.categoriesFilter = [...val.query.sc.split(',').map(v => this.parseCategorie(v))]
        } else {
          this.categoriesFilter = []
        }
      }
    },
    categoriesFilter: {
      immediate: true,
      deep: true,
      handler (val) {
        let filters = {
          ...this.$route.query,
          sc: val.map((v) => { return v[1]}).join(',')
        }
        if (Array.isArray(val) && (val.length === 3 || val.length === 0)) {
          delete filters['sc']
        }
        this.$router.push({query: filters})
      }
    },
    StatutFilter: {
      immediate: true,
      handler (val) {
        let filters = {
          ...this.$route.query,
          u: val.join(',')
        }
        if (Array.isArray(val) && (val.length === 3 || val.length === 0)) {
          delete filters['u']
        }
        this.$router.push({query: filters})
      }
    }
  },
  methods: {
    parseCategorie (scId) {
      const sc = this.sousCategories.find((sc) => { return sc.id === parseInt(scId)})
      if (sc && sc.categories) {
        return [sc.categories.id, sc.id]
      } else {
        return []
      }
    },
    onPositionChange (position)
    {
      let newPosition = position === null ? null : position.position.join(',')
      let filters = {
        ...this.$route.query,
        c: newPosition
      }
      if (newPosition === null) {
        delete filters.c
      }
      this.$router.push({query: filters})
    }
  },
  computed: {
    sousCategories () {
      return this.$symfony.sousCategories
    },
    symfony () {
      return this.$symfony
    },
    categories () {
      if (!this.$symfony.categories) {
        return []
      } else {
        return this.$symfony.categories
      }
    },
    categoriesOptions () {
      return this.categories.filter((cat) => {
        return cat.id && cat.nom
      }).map((v) => {
        const data = {
          value: v.id,
          label: v.nom,
          // children: v.sousCategories.map((v) => {return {value: v.id, label: v.nom}})
        }
        if (Array.isArray(v.sousCategories)) {
          data.children = v.sousCategories.map((v) => {return {value: v.id, label: v.nom}})
        }
        return data
      })
    },
    marquesChildrens () {
      if (this.$symfony.marques) {
        return this.$symfony.marques.map((v) => {
          return {
            value: v.id,
            label: v.nom
          }
        })
      } else {
        return []
      }
    },
    TraitOptions () {
      return [
        {
          value: 'mo',
          label: 'Modèle'
        },
        {
          value: 'ma',
          label: 'Marque',
          // children: this.marquesChildrens
        },
        {
          value: 'ca',
          label: 'Categories',
          children: this.categoriesChildrens
        }
      ]
    },
    UserStatuts () {
      return this.$route.query && this.$route.query.u ? this.$route.query.u.split(',') : []
    },
    includedOptions () {
      return this.UserStatutsOptions.filter((u) => {
        return this.UserStatuts.includes(u.value + '')
      })
    },
    OrderBy: {
      get () {
        return this.$route.query.s ? this.$route.query.s : 'pertinence'
      },
      set (val) {
        let filters = {
          ...this.$route.query,
          s: val
        }
        this.$router.push({query: filters})
      }
    },
    cityDropdownClass(){
      return this.toggleFilter === 'city' ? 'active' : ''
    }
  }
}
</script>

<style lang="scss">

</style>