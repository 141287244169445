<template>
  <ul class="navbar-nav mr-auto desktop-only">
    <li class="nav-item dropdown">
      <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
         aria-haspopup="true" aria-expanded="false">
        {{$t('navbar.dropdown.title')}}
      </a>
      <div class="dropdown-menu" aria-labelledby="navbarDropdown">
        <a class="dropdown-item" :href="path('lighty_share_service_louez')">{{$t('navbar.dropdown.item.1')}}</a>
        <a class="dropdown-item" :href="path('lighty_share_service_rentabilisez')">{{$t('navbar.dropdown.item.2')}}</a>
      </div>
    </li>
    <li class="nav-item">
      <a class="nav-link" href="//blog.lightyshare.com" target="_blank">{{$t('navbar.item.1')}}</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" href="//support.lightyshare.com" target="_blank">{{$t('navbar.item.2')}}</a>
    </li>
  </ul>

  <div class="navbar-nav mobile-only">
    <form method="POST" :action="path('lighty_share_user_search')" accept-charset="UTF-8">
      <div class="header-search-form d-flex">
        <input class="form-control" id="type" name="typeof" type="hidden" value="rent">
        <input type="text" id="recherche" name="string" value="" :placeholder="$t('navbar.header-search.input-search')">
        <button class="search-button"><i class="fas fa-search"></i></button>

      </div>
    </form>
  </div>
</template>

<script>
  export default {
    name: "PublicMenu"
  }
</script>