<template>
  <div v-bind="$attrs">
    <div class="item" @click="goToAd">
      <div class="featured-product-block">
        <a class="image-container">
          <img :src="adCoverPath" alt="">
          <span v-if="ad && ad.productType === 1" class="pack-label">Pack</span>
        </a>
        <div class="description">
          <p class="title">
            <a href="">{{ title }}</a>
          </p>
          <p class="text"><a class="feature-block text-overlay" href="/">{{fullCity}}</a></p>
          <p class="name">
            <a :href="shopPath">{{ fullname }}
              <span v-if="isExpert" class="expert-badge"><i class="fa fa-star" aria-hidden="true"></i> Expert</span>
              <label v-if="statutsBadge">{{statutsBadge}}</label> <i class="fa fa-truck invisible" aria-hidden="true"></i>
            </a>
          </p>
        </div>
        <a class="feature-block text-overlay">
          <div class="footer">
            <div class="star-rating">
              <i class="fas fa-star"></i>
              <span>{{ moyenne }} </span><small>({{ nbStars }})</small>
            </div>
            <div class="price">
              <span>{{price}}</span>
              <span>Par jour</span>
            </div>
          </div>
        </a>
        <el-button type="info" :loading="loadingButton" v-if="hasButton" class="btn btn-short btn-primary mt-4" style="width: 100%;" @click.stop="onButtonClick">{{ button }}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AnnonceCard",
  emits: ['button-click'],
  props: {
    loadingButton: {
      type: Boolean,
      default: false
    },
    button: {
      type: String,
      default: ''
    },
    ad: {
      type: Object,
      default () {
        return null
      }
    }
  },
  methods: {
    onButtonClick () {
      this.$emit('button-click', this.ad)
    },
    goToAd () {
      window.open('/annonce/show/' + this.ad.id);
    }
  },
  computed: {
    hasButton () {
      return this.button.length
    },
    title () {
      if (this.ad && this.ad.title) {
        return this.ad.title
      }
      if (this.ad && this.ad.modele && this.ad.modele.marque) {
        return this.ad.modele.marque.nom + ' ' + this.ad.modele.nom
      }
      return this.ad.titre
    },
    adCoverPath ()
    {
      let noImage = '/img/sans-photo.jpg'
      if (this.ad && this.ad.cover && this.ad.cover.path && this.ad.cover.path.length) {
        let pathSegment = this.ad.cover.path.split('/')
        pathSegment[pathSegment.length - 1] = pathSegment[pathSegment.length - 1]
        return '/display-media' + pathSegment.join('/') + '?w=300&r=1'
      }
      return this.ad && Array.isArray(this.ad.imagesannonce) && this.ad.imagesannonce.length && this.ad.imagesannonce[0].imageName && this.ad.user && this.ad.user.id ? '/display-media/images-upload/' + this.ad.user.id + '/annonces/' + this.ad.id + '/' + this.ad.imagesannonce[0].imageName + '?w=300&r=1' : noImage
    },
    fullCity () {
      if (this.ad && this.ad.user && this.ad.user.currentAdresse) {
        return this.ad.user.currentAdresse.ville + ', ' + this.ad.user.currentAdresse.codePostal
      }
      return ''
    },
    fullAdresse () {
      return ''
    },
    fullname () {
      return this.ad.user && this.ad.user.fullName ? this.ad.user.fullName : ''
    },
    shopPath () {
      return '/annonce/show/' + this.ad.id
    },
    moyenne () {
      return this.ad.user && this.ad.user.notationAverage ? this.ad.user.notationAverage : 0
    },
    nbStars () {
      const user = this.ad.user
      if (user) {
        return user.oneStar + user.twoStar + user.threeStar + user.fourStar + user.fiveStar
      } else {
        return 0
      }
    },
    price () {
      let price = false
      if (typeof this.ad.tarif === 'string') {
        price = this.ad.tarif
      } else if (this.ad.tarif){
        price = this.ad.tarif.prixUnJour
      }
      if (!price) {
        price = 0.00
      }
      return new Intl.NumberFormat("fr", {
        style: 'currency',
        currency: 'EUR'
      }).format(price)
    },
    statutsBadge () {
      let badge = false
      if (!this.ad.user) {
        return badge
      }
      if (this.ad.user.statutUser === 2 || this.ad.user.statutUser === 5) {
        badge = 'PRO'
      } else if (this.ad.user.statutUser === 4) {
        badge = 'LOUEUR PRO'
      } else if (this.ad.user.statutUser === 3) {
        badge = 'Association'
      }
      return badge
    },
    isExpert ()
    {
      return this.ad.user && this.ad.user.isExpert ? this.ad.user.isExpert : false
    }
  }
}
</script>

<style scoped>
.pack-label {
  position: absolute;
  background: var(--el-color-primary);
  font-size: 12px;
  padding: 2px 4px;
  border-radius: 6px;
  color: #fff;
  z-index: 10;
  right: 6px;
  bottom: 6px;
}
</style>
