<template>
  <div class="main-map-wrapper">
    <div id="map" ref="mapboxGl"></div>
  </div>
</template>

<script>
import Mapbox from 'mapbox-gl';
import AnnonceCard from './AnnonceCard.vue'

Mapbox.accessToken = import.meta.env.VITE_MAPBOX_ACCESS_TOKEN
export default {
  name: "Map",
  data () {
    return {
      markers: []
    }
  },
  props: {
    position: {
      type: [Array, Boolean]
    },
    ads: {
      type: [Array],
      default () {
        return []
      }
    }
  },
  computed: {
    // mapboxToken () {
    //   return import.meta.env.MAPBOX_ACCESS_TOKEN
    // },
    currentPosition () {
      return this.boxMarkersCenter ? this.boxMarkersCenter : Array.isArray(this.position) && this.position.length === 2 ? this.position : [	2.3522219, 48.856614]
    },
    positionsMarkers () {
      return this.ads.map((ad) => {
        return [ad.user.currentAdresse.lng, ad.user.currentAdresse.lat]
      })
    },
    boxMarkersCenter () {
      const bottomRight = this.boxMarkersEdges ? this.boxMarkersEdges.bottomRight : false
      const topLeft = this.boxMarkersEdges ? this.boxMarkersEdges.topLeft : false
      if (topLeft && bottomRight) {
        const coords = [
            parseFloat(bottomRight[0]) + ((parseFloat(topLeft[0]) - parseFloat(bottomRight[0]))/2),
            parseFloat(topLeft[1]) + ((parseFloat(bottomRight[1]) - parseFloat(topLeft[1]))/2)
        ]
        coords.map((v) => v.toFixed(6))
        return coords
      } else {
        return false
      }
    },
    boxMarkersEdges () {
      return this.positionsMarkers.reduce((edges, current) => {
        if (edges === false) {
          edges = {
            topLeft: [...current],
            bottomRight: [...current]
          }
        } else {
          if (parseFloat(edges.topLeft[0]) < parseFloat(current[0])) {
            edges.topLeft[0] = current[0]
          }
          if (parseFloat(edges.topLeft[1]) > parseFloat(current[1])) {
            edges.topLeft[1] = current[1]
          }
          if (parseFloat(edges.bottomRight[0]) > parseFloat(current[0])) {
            edges.bottomRight[0] = current[0]
          }
          if (parseFloat(edges.bottomRight[1]) < parseFloat(current[1])) {
            edges.bottomRight[1] = current[1]
          }
        }
        return edges
      }, false)
    }
  },
  watch: {
    boxMarkersCenter: {
      handler (val) {
        if (val && this.mapbox) {
          // this.mapbox.easeTo({center: val, duration: 300});
          // this.mapbox.fitScreenCoordinates(this.boxMarkersEdges.topLeft, this.boxMarkersEdges.bottomRight, this.mapbox.getBearing(), {
          //   padding: {top: 50, bottom:50, left: 50, right: 50}
          // })
        }
      }
    },
    boxMarkersEdges: {
      deep: true,
      immediate: true,
      handler (val) {
        if (val && this.mapbox) {
          // console.log('move camera to box markers edge')
          this.moveCamera(val)
          // this.mapbox.fitScreenCoordinates(val.topLeft, val.bottomRight, this.mapbox.getBearing(), {
          //   padding: {top: 10, bottom:25, left: 15, right: 5}
          // })
        }
      }
    },
    ads: {
      deep: true,
      immediate: true,
      handler (val) {
        // this.mapbox.easeTo({center: val, duration: 300});
        if (this.mapLoaded) {
          this.addAdsMarkers(val)
        }
      }
    },
    currentPosition: {
      handler (pos, oldPos) {
        if (Array.isArray(pos) && Array.isArray(oldPos) && pos.length > 0 && (pos[1] !== oldPos[1] || pos[0] !== oldPos[0])) {
          // this.mapbox.easeTo({center: pos, duration: 300});
        }
      }
    }
  },
  methods: {
    moveCamera (val) {
      if (this.mapLoaded && Array.isArray(val.topLeft) && Array.isArray(val.bottomRight)) {
        const bbox = [val.topLeft, val.bottomRight];
        if (val.topLeft[0] === val.bottomRight[0] && val.topLeft[1] === val.bottomRight[1]) {
          this.mapbox.easeTo({center: val.topLeft, duration: 300});
        } else {
          const newCameraTransform = this.mapbox.cameraForBounds(bbox, {
            padding: {top: 50, bottom:50, left: 50, right: 50}
          });
          this.mapbox.easeTo(newCameraTransform);
        }
      } else {
        console.warn('Abort adding Ads Markers because map is not loaded')
      }
    },
    addAdsMarkers (ads) {
      if (this.mapLoaded) {
        this.cleanMarkers()
        for (let ad of ads) {
          this.addMarker(ad)
        }
        this.renderMarkers()
      } else {
        console.warn('Abort adding Ads Markers because map is not loaded')
      }
    },
    addPopupCard (ad) {
      let title = ad.titre
      let noImage = 'img/sans-photo.jpg'
      let shopPath = '/annonce/show/' + ad.id
      let adCoverPath = ad && ad.cover && ad.cover.path ? ad.cover.path + '?w=300&r=1' : noImage
      let fullCity = ad.user.currentAdresse.ville + ', ' + ad.user.currentAdresse.codePostal
      let fullname = ad.user.fullName
      let moyenne = ad.user.notationAverage
      let user = ad.user
      let nbStars = user.oneStar + user.twoStar + user.threeStar + user.fourStar + user.fiveStar
      let tarif = false
      if (typeof ad.tarif === 'string') {
        tarif = ad.tarif
      } else if (ad.tarif) {
        tarif = ad.tarif.prixUnJour
      }
      if (typeof tarif === 'undefined') {
        tarif = '0.00'
      }
      let price = new Intl.NumberFormat("fr", {
        style: 'currency',
        currency: 'EUR'
      }).format(tarif)
      let badge = false
      if (ad.user.statutUser === 2 || ad.user.statutUser === 5) {
        badge = 'PRO'
      } else if (ad.user.statutUser === 4) {
        badge = 'LOUEUR PRO'
      } else if (ad.user.statutUser === 3) {
        badge = 'Association'
      }
      let isExpert = ad.user.isExpert
      let badgeExpert = isExpert ? `<span class="expert-badge"><i class="fa fa-star" aria-hidden="true"></i> Expert</span>` : ``
      let statusBadge = badge ? `<label v-if="statutsBadge">${badge}</label>` : ''
      let popup = `
        <div class="featured-product-block map-info-popup" onclick="window.open('${shopPath}')">
          <div class="image-container">
            <img src="${adCoverPath}" alt="">
          </div>
          <div class="description">
              <p class="title">
                <a href="">${title}</a>
              </p>
              <p class="text"><a class="feature-block text-overlay" href="/">${fullCity}</a></p>
              <p class="name">
                  <a href="${shopPath}">${fullname}
                      ${badgeExpert}
                      ${statusBadge}
                      <i class="fa fa-truck invisible" aria-hidden="true"></i>
                  </a>
              </p>
          </div>
          <a class="feature-block text-overlay" href="${shopPath}">
              <div class="footer">
                  <div class="star-rating">
                      <i class="fas fa-star"></i>
                      <span>${moyenne} </span><small>(${nbStars})</small>
                  </div>
                  <div class="price">
                      <span>${price}</span>
                      <span>Par jour</span>
                  </div>
              </div>
          </a>
      </div>
      `
      return popup
    },
    addMarker (ad)
    {
      let el = document.createElement('div');
      el.className = 'marker';
      el.setAttribute('id', 'marker-' + ad.id)
      el.setAttribute('rel', ad.id);
      el.style = 'transition: all 0s linear;';
      let price = false
      if (typeof ad.tarif === 'string') {
        price = ad.tarif
      } else if (ad.tarif) {
        price = ad.tarif.prixUnJour
      }
      if (typeof price === 'undefined') {
        price = '0.00'
      }
      el.innerText = price + ' €';
      let marker = new Mapbox.Marker(el)
      // console.log(ad.user.currentAdresse)
      if (ad.user.currentAdresse && ad.user.currentAdresse.lng && ad.user.currentAdresse.lat) {
        const coord = [ad.user.currentAdresse.lng, ad.user.currentAdresse.lat]
        marker.setLngLat(coord)
        marker.setPopup(new Mapbox.Popup({
          closeButton: false,
          maxWidth: '300px'
        }).setHTML(this.addPopupCard(ad)))
        this.markers.push({
          marker,
          ad
        })
      }
    },
    cleanMarkers ()
    {
      for (let marker of this.markers) {
        marker.marker.remove()
      }
      this.markers = []
    },
    renderMarkers ()
    {
      for (let marker of this.markers) {
        marker.marker = marker.marker.addTo(this.mapbox)
      }
    }
  },
  created () {
  },
  mounted () {
    this.mapbox = new Mapbox.Map({
      container: this.$refs.mapboxGl,
      style: 'mapbox://styles/jonathan-lightyshare/ckia4xpw3764n1apc23o4t3hy', // stylesheet location
      center: this.currentPosition,
      zoom: 11,
      minZoom: 6,
      maxZoom: 200,
      scrollZoom: true
    });
    this.mapbox.on('load',() => {
      this.mapLoaded = true
      this.addAdsMarkers(this.ads)
      this.moveCamera(this.boxMarkersEdges)
    })
  },
}
</script>

<style lang="scss">
  .main-map-wrapper #map {
    .marker {
      &:hover, &.active {
        //color: #fc2249;
        box-shadow: 0 0 0 2pt #fc2249;
      }
      &.active {
        z-index: 9;
      }
    }
  }
</style>
