<template>
  <p v-if="root">{{description}}</p>
  <el-progress v-if="root" :percentage="percent">
    {{value}}
  </el-progress>
  <el-collapse v-if="hasChildren">
    <el-collapse-item v-for="detail in details" >
      <template #title>
        {{detail.description}}
      </template>
      <el-progress :percentage="percent">
        {{value}}
      </el-progress>
      <div class="children">
        <ExplainationSearch :description="detail.description" :value="detail.value" :details="detail.details" :scoreTotal="value" :root="false"/>
      </div>
    </el-collapse-item>
  </el-collapse>

</template>
<script>
  export default {
    props: {
      root: {
        type: Boolean,
        default: true
      },
      description: {
        type: String
      },
      value: {
        type: Number
      },
      details: {
        type: Array
      },
      scoreTotal: {
        type: [Boolean, Number],
        default: false
      }
    },
    computed: {
      hasChildren () {
        return typeof this.details !== 'undefined'
      },
      percent () {
        if (this.scoreTotal === false) {
          return 100
        } else if (typeof this.value === 'number' && typeof this.scoreTotal === 'number') {
          return (this.value/this.scoreTotal) * 100
        } else {
          return 100
        }
      }
    },
    data () {
      return {
        activeTabs: []
      }
    },

  }
</script>

<style lang="scss">
  .percentage-label {
    display: block;
    width: 100%;
    margin-top: 10px;
    font-size: 12px;
  }
  .progress-elastic {
    width: 80%;
  }
  .el-collapse.children {
    border: none;
  }
  .children .el-collapse-item__header {
    border-bottom: none;
  }
  .children {

  }
</style>