<template>
  <div v-if="totalPages > 1" class="paginator row">
    <div class="col-sm-3"></div>
    <div class="col-sm-7">
      <div class="dataTables_paginate paging_bs_normal">
        <ul class="pagination">
          <li class="prev" v-if="currentPage !== 1">
            <a href="#" @click.stop.prevent="loadDataThrottle(currentPage - 1)">
              <span class="glyphicon glyphicon-chevron-left" v-if="currentPage !== 1">Previous</span>
            </a>
          </li>
          <li v-for="n in renderTotal" :class="renderPageNumber(n) === currentPage ? 'active' : ''">
            <a href="#" @click.stop.prevent="loadDataThrottle(renderPageNumber(n))" >
              <span>{{ renderPageNumber(n)}}</span>
            </a>
          </li>
          <li class="next" v-if="currentPage !== totalPages" @click.stop.prevent="loadDataThrottle(currentPage + 1)">
            <a href="#">
              <span class="glyphicon glyphicon-chevron-right">Next </span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import {debounce} from "../utils/TimeExecution.js";
// import {searchAction} from "../services/search";

export default {
  name: "Paginator",
  props: {
    nbItems: {
      type: Number
    },
    currentPage: {
      type: Number,
      default: 1
    },
    perPage: {
      type: Number,
      default: 18
    }
  },
  data () {
    return {
    }
  },
  computed: {
    totalPages () {
      return Math.ceil(this.nbItems / this.perPage)
    },
    renderTotal () {
      if (this.totalPages <= 5) {
        return this.totalPages
      } else {
        return 5
      }
    },
    renderPageIsEnd () {
      return this.currentPage + 2 >= this.totalPages
    },
    renderPageEndResult () {
      let res = []
      for (let n = 1; n < 6; n++) {
        res.push(this.totalPages + (n - 5))
      }
      return res
    }
  },
  methods: {
    loadDataThrottle: debounce(function (data) {
      // console.log('change page')
      // console.log(data)
      this.$emit('change', data)
    }, 100, true),
    renderPageNumber (n) {
      if (this.totalPages > 5 && this.currentPage + 2 >= this.totalPages) {
        return this.totalPages + (n - 5)
      } else if (n <= 2) {
        return this.currentPage - (3 + n) <= 0 ? n : this.currentPage - 3 + n
      } else if (n === 3) {
        return this.currentPage < 3 ? n : this.currentPage
      } else {
        if (this.currentPage < 3) {
          return n
        }
        // if (this.currentPage + n > this.totalPages) {
        //   return this.currentPage + (n - 6)
        // }
        return this.currentPage < 3 ? n : this.currentPage + (n - 3)
      }
    }
  }
}
</script>

<style scoped>
.paginator {
  width: 100%;
}
</style>