export default {
    computed: {
        countTotalNotification () {
            if (this.notifications) {
                const keys = Object.keys(this.notifications);
                const total = keys.reduce((acc, item) => {
                    acc += this.notifications[item]
                    return acc
                }, 0)
                return total <= 9 ? total : '+9'
            } else {
                return 0
            }
        },
        countNewMessages () {
            const total = this.notifications && this.notifications.newMessages
            return total <= 9 ? total : '+9'
        },
        countNewLocations () {
            const total = this.notifications && this.notifications.newLocations
            return total <= 9 ? total : '+9'
        },
        countNewRentals () {
            const total = this.notifications && this.notifications.newRentals
            return total <= 9 ? total : '+9'
        },
        countNewPanierSessions () {
            const total = this.notifications && this.notifications.newPanierSessions
            return total <= 9 ? total : '+9'
        }
    }
}